import * as React from "react";

import { Head } from "../components/Head";
import { Layout } from "../components/Layout";

import {
  SectionContainer,
  SectionWrapper
} from "../components/Section";

import { ErrorHero } from "../components/Hero";


const NotFoundPage: React.FC = () => {
  return (
    <Layout>
      <Head
        title="404: Not Found"
      />

      <SectionWrapper
        padding={{
          base: '38px 0 32px',
          lg: '60px 0 48px'
        }}
      >
        <SectionContainer>
          <ErrorHero />
        </SectionContainer>
      </SectionWrapper>
    </Layout>
  )
}


export default NotFoundPage;
